const support = process.env.VUE_APP_SUPPORT_DEPT_ROOT
const inner = process.env.VUE_APP_INNER_DEPT_ROOT
const external = process.env.VUE_APP_EXTERNAL_DEPT_ROOT

const SUPPORT = "support"
const INNER = "inner"
const EXTERNAL = "external"

/**
 * 获取部门根节点：运维人员部门，顾问部门，伊顿部门
 * @param {*} name 
 * @returns 
 */
export function getDeptRootId(name) {
    switch (name) {
        case SUPPORT:
            return support
        case INNER:
            return inner
        case EXTERNAL:
            return external
        default:
            return inner //默认返回伊顿部门根节点
    }
}

export function getEatonRootIds() {
    //根据权限显示时候全部：咨zhangxiantian 
    return inner + ',' + external
}
	<template>
  <div class="user-rule-creator" :title="rules">
    <user-chooser ref="userChooser" @choose="onChooseUser" />
    <div class="rule-form">
      <h3 class="form-name">
        <span style="color: green"
          ><a-icon type="check-circle" style="margin-right: 10px" /> 添加用户
        </span>
      </h3>
      <div class="section-item">
        <div class="section-title">
          所有用户<span style="margin-left: 10px">
            <a-checkbox v-model="form.all" @change="handleChange">
            </a-checkbox> </span
          ><span>（选择后选择所有用户）</span>
        </div>
      </div>
      <div class="section-item">
        <div class="section-title">
          部门<span>（以下选择的部门将会包含在内）</span>
        </div>
        <div class="section-body">
          <a-tree-select
            :tree-checkable="true"
            tree-default-expand-all
            :allowClear="true"
            :dropdownStyle="{ maxHeight: '220px', overflow: 'auto' }"
            :treeData="deptTreeData"
            @change="handleChange"
            v-model="form.dept"
            placeholder="选择部门"
          >
          </a-tree-select>
        </div>
      </div>
      <div class="section-item">
        <div class="section-title">
          用户<span>（以下选择的用户将会包含在内）</span>
        </div>
        <div class="section-body">
          <a-tag
            v-for="(item, index) of _userData"
            :key="index"
            closable
            @close="closeUser(item.userId)"
          >
            {{ item.username }}
          </a-tag>
          <a-button
            shape="circle"
            size="small"
            icon="plus"
            @click="$refs.userChooser.setChoose('+')"
            >添加</a-button
          >

          <!-- <a-select
            v-if="userData.length"
            mode="multiple"
            @change="handleChange"
            placeholder="选择用户"
            v-model="form.user"
          >
            <a-select-option
              v-for="(r, index) in userData"
              :value="String(r.userId)"
              :key="index"
              >{{ r.username + r.userId }}</a-select-option
            >
          </a-select> -->
        </div>
      </div>
    </div>
    <div class="rule-form form2">
      <h3 class="form-name">
        <span style="color: red"
          ><a-icon type="stop" style="margin-right: 10px" /> 排除用户
        </span>
      </h3>
      <div class="section-item">
        <div class="section-title">
          用户<span>（以下选择的用户将会包含在内）</span>
        </div>
        <div class="section-body">
          <a-tag
            v-for="(item, index) of _reduceUserData"
            :key="index"
            closable
            @close="closeReduceUser(item.userId)"
          >
            {{ item.username }}
          </a-tag>
          <a-button
            size="small"
            shape="circle"
            icon="plus"
            @click="$refs.userChooser.setChoose('-')"
            >添加</a-button
          >
          <!-- <a-select
            v-if="userData.length"
            mode="multiple"
            @change="handleChange"
            placeholder="选择用户"
            v-model="form.reduceUser"
          >
            <a-select-option
              v-for="(r, index) in userData"
              :value="String(r.userId)"
              :key="index"
              >{{ r.username + r.userId }}</a-select-option
            >
          </a-select> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeptInputTree from "@/views/system/dept/DeptInputTree";
import { getDeptRootId, getEatonRootIds } from "./../../utils/tools";
import UserChooser from "./UserChooser";

export default {
  components: {
    DeptInputTree,
    UserChooser,
  },
  data() {
    return {
      entities: [],
      form: {
        all: false,
        dept: [], // 部门
        user: [], // 用户
        reduceUser: [], // 被排除用户
      },
      userData: [],
      deptTreeData: [],
      rules: null,
    };
  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
    onChooseUser(type, list) {
      if (type == "+") {
        this.form.user = [...new Set(this.form.user.concat(list))];
      }
      if (type == "-") {
        this.form.reduceUser = [...new Set(this.form.reduceUser.concat(list))];
      }
      this.handleChange();
    },
    closeReduceUser(id) {
      this.form.reduceUser = this.form.reduceUser.filter(
        (item) => item != id
      );
      this.handleChange();
    },
    closeUser(id) {
      this.form.user = this.form.user.filter((item) => item != id);
      this.handleChange();
    },
    loadBaseData() {
      this.$get("user/simple-list", {
        includeFollow: true,
        rootDeptIds: getEatonRootIds(),
      }).then((r) => {
        this.userData = r.data;
      });

      this.$get("dept", { rootDeptIds: getDeptRootId() }).then((r) => {
        this.deptTreeData = r.data.rows.children;
      });
    },
    handleChange() {
      let str = "";
      if (this.form.all) {
        str += "+<*>";
      }
      if (this.form.user && this.form.user.length > 0) {
        str += "+<user:" + this.form.user.join(",") + ">";
      }
      if (this.form.dept && this.form.dept.length > 0) {
        str += "+<dept:" + this.form.dept.join(",") + ">";
      }
      if (this.form.reduceUser && this.form.reduceUser.length > 0) {
        str += "-<user:" + this.form.reduceUser.join(",") + ">";
      }
      this.rules = str;
      this.$emit("valueChange", str);
    },
    setRules(value) {
      this.rules = value;
      if (value) {
        this.$get(`group/convert-rules/${this.rules}`).then((resp) => {
          this.entities = resp.data.entities;
          this.form.all = this.initData("*", "+");
          this.form.user = this.initData("user", "+");
          this.form.reduceUser = this.initData("user", "-");
          this.form.dept = this.initData("dept", "+");
        });
      } else {
        this.entities = [];
        this.form = {
          dept: [], // 部门
          user: [], // 用户
          reduceUser: [], // 被排除用户
        };
      }
    },
    initData(type, operation) {
      let obj = this.entities.filter(
        (item) => item.type === type && item.operation === operation
      )[0];

      switch (type) {
        case "dept":
          return obj ? Object.keys(obj.typeMap || {}) : [];
        case "user":
          return obj ? Object.keys(obj.typeMap || {}) : [];
        case "*":
          return Boolean(obj);
      }
    },
  },
  computed: {
    _reduceUserData() {
      if (!this.userData || this.userData.length == 0) {
        return [];
      }
      return this.form.reduceUser.map((id) =>
        this.userData.find((u) => u.userId == id)
      );
    },
    _userData() {
      if (!this.userData || this.userData.length == 0) {
        return [];
      }
      return this.form.user.map((id) =>
        this.userData.find((u) => u.userId == id)
      );
    },
  },
};
</script>

<style lang="less" scoped>
.user-rule-creator {
    border-left: 20px solid #efe;
    &:hover {
    border-left: 20px solid #eef;
    }

    .rule-form {
        border: 1px solid #DFDFDF;
        padding: 0 10px;
        // background: #00ff0008;

        &.form2 {
            margin-top: 5px // background: #ff000008;
        }

        .form-name {
            font-size: 18px;
            margin-bottom: 0;
            font-weight: 100;
        }

        .section-item {
            border-top: 1px dotted #eee;
            margin-bottom: 10px;

            .section-title {
                font-size: 14px;
                font-weight: 100;
                color: black;

                span {
                    color: orange;
                    font-size: 12px;
                }
            }

            .section-body {}
        }
    }
}

</style>

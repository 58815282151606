<template>
  <a-drawer
    title="添加人员"
    width="320"
    :closable="true"
    @close="onClose"
    :visible="showView"
  >
    <div>
      <a-input v-model="searchKey" placeholder="过滤条件" />
    </div>
    <a-list
      size="small"
      bordered
      :data-source="filterUsers"
      style="max-height: 500px; overflow: scroll; margin: 10px 0 20px 0"
    >
      <a-list-item
        slot="renderItem"
        :style="{
          cursor: 'pointer',
          background: item.choose ? '#fefeee' : 'none',
        }"
        @click="item.choose = !item.choose"
        slot-scope="item"
      >
        <a-icon v-if="item.choose" style="color: green; margin-right: 10px" type="check" />
        姓名：{{ item.username }} 工号：{{ item.empno }}
      </a-list-item>
    </a-list>
    <div>
      <a-tag
        color="green"
        style="margin-top: 5px"
        closable
        @close="removeUser(item)"
        v-for="(item, index) of selectedUsers"
        :key="index"
        >{{ item.username }}</a-tag
      >
    </div>
    <a-button
      type="primary"
      style="float: right; margin-top: 20px"
      :disabled="buttonDisble"
      @click="addUsers"
      >添加</a-button
    >
  </a-drawer>
</template>

<script>
import { getEatonRootIds } from "./../../utils/tools";

export default {
  data() {
    return { type: null, searchKey: "", userData: [], showView: false };
  },
  mounted() {
    this.loadBaseData();
  },
  methods: {
    onClose() {
      this.reset();
      this.showView = false;
    },
    setChoose(type) {
      this.type = type;
      this.reset();
      this.showView = true;
    },
    removeUser(user) {
      user.choose = false;
    },
    addUsers() {
      this.$emit(
        "choose",
        this.type,
        this.selectedUsers.map((item) => item.userId)
      );
      this.reset();
      this.showView = false;
    },
    reset() {
      this.userData = this.userData.map((item) => {
        item.choose = false;
        return item;
      });
    },
    loadBaseData() {
      this.$get("user/simple-list", {
        includeFollow: true,
        rootDeptIds: getEatonRootIds(),
      }).then((r) => {
        this.userData = r.data.map((item) => {
          item.choose = false;
          return item;
        });
      });
    },
  },
  computed: {
    filterUsers() {
      return this.userData.filter((item) => {
        if (item.username && item.username.indexOf(this.searchKey) >= 0)
          return true;
        if (item.empno && item.empno.indexOf(this.searchKey) >= 0) return true;
        if (item.deptName && item.deptName.indexOf(this.searchKey) >= 0)
          return true;
        return false;
      });
    },
    selectedUsers() {
      return this.userData.filter((item) => item.choose);
    },
    buttonDisble() {
      return this.selectedUsers.length == 0;
    },
  },
};
</script>
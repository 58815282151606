//文件上传地址
export const UPLOAD_API = `${process.env.VUE_APP_BASE_API}/upload/imgs`

//头像 图片
export const IMAGE_FACE = "image-face/"
//新闻 图片
export const IMAGE_NEWS = "image-news/"
//分享消息 文件
export const IMAGE_MESSAGE_FENXIANG = "image-message-fenxiang/"
export const PDF_MESSAGE_FENXIANG = "pdf-message-fenxiang/"
export const VIDEO_MESSAGE_FENXIANG = "video-message-fenxiang/"
//产品 图片
export const IMAGE_PRODUCT = "image-product/"
//荣誉 图片
export const IMAGE_HONOR = "image-honor/"
//宣传 图片
export const IMAGE_PROPAGATE = "image-propagate/"
//文化 图片
export const IMAGE_CULTURE = "image-culture/"
//活动 图片
export const IMAGE_ACTIVITY = "image-activity/"
//社群 图片
export const IMAGE_COMMUNITY = "image-community/"
//pdf 文件
export const PDF_POSTER = "pdf-xxxxx/"
export const PDF_PRODUCT = "pdf-product/"
export const PDF_HONOR = "pdf-honor/"
export const PDF_ACTIVITY = "pdf-activity/"
export const PDF_CULTURE = "pdf-culture/"

// excel 文件
export const EXCEL_THREEZHHIZHU = "excel-three-zhizhu/"

//视频 文件
export const VEDIO_PROPAGATE = "vedio-propagate/"
//培训 视频
export const VEDIO_TRAINING = "vedio-training/"
//培训 视频
export const PDF_TRAINING = "pdf-training/"
//培训 视频
export const IMAGE_TRAINING = "image-training/"

export default {
    PDF_TRAINING: PDF_TRAINING,
    IMAGE_TRAINING: IMAGE_TRAINING,
    VEDIO_TRAINING: VEDIO_TRAINING,
    IMAGE_MESSAGE_FENXIANG,
    PDF_MESSAGE_FENXIANG,
    VIDEO_MESSAGE_FENXIANG
}
<template>
  <div>
    <a-upload
      name="files"
      :data="getData()"
      :action="api"
      :file-list="enclosureList"
      :before-upload="beforeUploadEnclosure"
      @change="handleUploadEnclosureChange"
    >
      <a-button> <a-icon type="upload" /> 上传附件 </a-button>
    </a-upload>
  </div>
</template>

<script>
import paths from "@/utils/upload-path-map.js";
export default {
  name: "UploadEnclosure",
  props: {
    path: { type: String, default: "enclosure/" },
    api: {
      type: String,
      default: `${process.env.VUE_APP_IMG_API}/upload/imgs`,
    },
    accept: {
      type: String,
      default: "image/png,image/jpg,image/jpeg,.pdf,.xlsx",
    },
    acceptArr: {
      type: Array,
      default: () => [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-powerpoint",
        "application/vnd.ms-excel",
        "application/msword",
        "video/mp4",
      ],
    },
    enclosureList: { type: Array, default: [] },
    limit: { type: Number, default: 1 },
    autoPath: { type: Boolean, default: false },
    module: { type: String, default: "" },
  },
  data() {
    return {
      paths: paths,
      autoPathDetail: undefined,
    };
  },
  methods: {
    handleUploadEnclosureChange({ file, fileList }) {
      // console.log("file附件======>", file);
      let enclosureList = fileList.slice(-1);
      let urls = [];
      if (file.status === "done") {
        urls = enclosureList.map((item) => item.response[0]);
      }
      this.$emit("_change", enclosureList, urls);
      switch (file.status) {
        case "error":
          // this.$emit("_change_error");
          this.$message.error('上传出错！')
          break;
        case "success":
          break;
        case "done":
          this.$emit("_change_done", enclosureList, urls);
          break;
        case "uploading":
          break;
        case "removed":
          this.$emit("_change_removed", enclosureList, urls);
          break;
      }
    },
    beforeUploadEnclosure(file) {
      let arr = this.acceptArr;
      console.log("附件的文件格式===>", file.type);
      const isConfirmFormat = arr.includes(file.type);
      if (!isConfirmFormat) {
        this.$message.error("不支持的文件类型");
      }
      const isLt1G = file.size / 1024 / 1024 < 1024;
      if (!isLt1G) {
        this.$message.error("文件大小不能超过 1G!");
      }
      if (this.autoPath) {
        this.filterPath(file.type);
      }
      return new Promise((resolve, reject) => {
        isConfirmFormat && isLt1G
          ? resolve(isConfirmFormat && isLt1G)
          : reject(isConfirmFormat && isLt1G);
      });
    },
    filterPath(type) {
      let key = "";
      if (type.indexOf("image") > -1) {
        key = "IMAGE_" + this.module.toUpperCase();
      } else if (type.indexOf("application") > -1) {
        key = "PDF_" + this.module.toUpperCase();
      } else {
        key = "VEDIO_" + this.module.toUpperCase();
      }
      this.autoPathDetail = this.paths[key];
    },
    //防止报内部不允许改变外部传参的问题
    getData() {
      let json = {};
      if (this.autoPath) {
        json.path = this.autoPathDetail;
      } else {
        json.path = this.path;
      }
      return json;
    },
  },
};
</script>
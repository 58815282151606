<template>
  <a-tree-select
    :tree-checkable="treeCheckable"
    tree-default-expand-all
    :allowClear="true"
    :dropdownStyle="{ maxHeight: '220px', overflow: 'auto' }"
    :treeData="currentDeptTreeData"
    @change="onChange"
    v-model="value"
  >
  </a-tree-select>
</template>

<script>
export default {
  props: ["topDeptIds", "treeCheckable", "data"],
  name: "DetpInputTree",
  data() {
    return {
      deptTreeData: [],
      value: undefined,
      label: undefined,
    };
  },
  methods: {
    setRootDeptIds(topDeptIds) {
      this.$get("dept", { rootDeptIds: topDeptIds }).then((r) => {
        this.deptTreeData = r.data.rows.children;
      });
    },
    reset() {
      this.value = "";
    },
    onChange(value, label, extra) {
      // console.log("value, label, extra", value, label, extra);
      this.label = label[0];
    },
  },
  mounted() {
    this.value = this.data;
    if (this.data) this.value = this.data;
    this.setRootDeptIds(this.topDeptIds)
  },
  watch: {
    data() {
      this.value = this.data;
    },
    value(value) {
      this.$emit("change", value, this.label);
    }
  },
  computed: {
    currentDeptTreeData() {
      return this.deptTreeData;
    },
  },
};
</script>
